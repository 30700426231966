import { MenuEntry } from '@nerveglobal/greenhouse-sx-uikit'

export const config: MenuEntry[] = [
  {
    label: "Exchange",
    href: "https://sx.trade.greenhousedex.com/",
  },
  {
    label: "Farms",
    href: "https://sx.greenhousedex.com/farms",
  },
  {
    label: "Stake",
    href: "https://sx.greenhousedex.com/stake",
  },
  {
    label: "Analytics",
    href: "https://sx.analytics.greenhousedex.com",
  },
];
export const sideconfig: MenuEntry[] = [
];

export default config