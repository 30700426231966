import { ConnectorNames } from '@nerveglobal/greenhouse-sx-uikit'
import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import WalletConnectProvider from "@maticnetwork/walletconnect-provider"
import { NetworkConnector } from './NetworkConnector'

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '56')

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  // eslint-disable-next-line no-return-assign
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: [416, 97],
})

export const bscConnector = new BscConnector({ supportedChainIds: [56] })

// mainnet only
export const walletconnect = new WalletConnectProvider({
  host: NETWORK_URL,
  callbacks: {
    onConnect: console.log('connected'),
    onDisconnect: console.log('disconnected!')
  }
})

// mainnet only
export const walletLink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: "Web3-react Demo",
  supportedChainIds: [416, 97],
 });

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector,
  [ConnectorNames.WalletLink]: walletLink,
}
