import React from 'react'
import styled from 'styled-components'
import { Card } from '@nerveglobal/greenhouse-sx-uikit'

export const BodyWrapper = styled(Card)`
  position: relative;
  min-height: 500px;
  width: 450px;
  background-color: #FFFFFF;
  z-index: 5;

  @media (max-width: 960px) {
    max-width: 100%;
  }

  @media (max-width: 640px) {
    max-width: 100%;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
